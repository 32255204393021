import { put, call, takeEvery } from "redux-saga/effects";

import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* getAllQuizSubjectsSaga(){
    yield takeEvery(actionType.GET_ALL_QUIZ_SUBJECTS, fetchAllQuizSubjects);
}

function* fetchAllQuizSubjects(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_ALL_QUIZ_SUBJECTS, payload: response})
    } catch (error) {
        console.log(error);
    }
}