import React, { Suspense, lazy } from "react";
import { requireAuth } from "../../util/auth";
import Meta from "../../components/meta-and-theme/meta/view/Meta";

const IssueListSection = lazy(() => import('../../components/support-management/issue-list-section/view/IssueListSection'));

function IssueListPage(props) {
  return (
    <>
      <Meta title="issue-list" />
      <Suspense fallback={<div>Loading...</div>}>
        <IssueListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(IssueListPage);
