import * as actionType from "../action/actionTypes";

const initialState = {
    allSubjects: []
}

export default function getAllSubjects(state = initialState, action) {

    switch (action.type) {

        case actionType.GOT_ALL_SUBJECTS: {
            return {
                ...state,
                allSubjects: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
    
}