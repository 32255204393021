import React, { useEffect, useState } from "react";

import Layout from "../../../miscellaneous/re-usable-components/layout/Layout";
import { getPrivacyPolicy } from "../../../../util/db";
import MdxFunctionCompiler from "../../../../util/mdxFunctionCompiler";
import ContentSkeleton from "../../../miscellaneous/re-usable-components/content-skeleton/ContentSkeleton";

const PrivacyPolicySection = () => {
  const [privacyContent, setPrivacyContent] = useState();
  const [isContentLoading, setIsContentLoading] = useState(true);


  const replaceWithEnvData = (value) => {
    if(!value) return;
    value = value.replaceAll("[app.name]", process.env.REACT_APP_APP_NAME);
    value = value.replaceAll("[app.url]", process.env.REACT_APP_COMPANY_NAME);
    value = value.replaceAll(
      "[policy.effective-date]",
      process.env.REACT_APP_POLICY_EFFECTIVE_DATE
    );
    return value;
  };

  useEffect(() => {
    setIsContentLoading(true);
    getPrivacyPolicy()
      .then((response) => {
        if (response.status === "success") {
          setPrivacyContent(response.value);
          setIsContentLoading(false);
        }
      })
      .catch((error) => {
        setIsContentLoading(true);
        console.log(error);
      });
  }, []);

  return (
    <Layout hScreen={"h-screen"} sectionName={"Privacy Policy"}>
        <div className="mx-auto max-w-7xl px-6 py-4 sm:py-1 lg:px-8">
          <div className="mx-auto divide-y divide-gray-900/10">
            <dl className="my-10 space-y-6 divide-y divide-gray-900/10">
              {isContentLoading && <ContentSkeleton />}
              {!isContentLoading &&
                <div id="privacy" className="default-style">
                  <MdxFunctionCompiler
                    jsxFunction={replaceWithEnvData(privacyContent)}
                  />
                </div>
              }
            </dl>
          </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicySection;
