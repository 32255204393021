import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const AddQuestionsForUserSection = lazy(() => import('../../components/quiz-management/add-questions-for-users/view/AddQuestionsForUsersSection'));

function AddQuestionsForUsers(props) {

  return (
    <>
      <Meta title="User admin" />
      <Suspense fallback={<div>Loading...</div>}>
        <AddQuestionsForUserSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AddQuestionsForUsers);
