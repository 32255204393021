import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import TermsSection from "../../components/content-and-information-pages/terms-section/view/TermsSection";

function TermsPage(props) {
  return (
    <>
      <Meta title="Terms Of Service" />
      <TermsSection />
    </>
  );
}

export default TermsPage;
