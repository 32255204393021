import AddMdxValue from "../../../miscellaneous/add-mdx-value/view/AddMdxValue";

const SetFooterContentSection = () => {
  return (
    <>
      <AddMdxValue />
    </>
  );
};

export default SetFooterContentSection;
