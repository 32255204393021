import {combineReducers} from "redux";

import getSubjectTypes from "./getSubjectTypes";
import getQuizSubjectTypes from "./getQuizSubjectTypes";
import getAllSubjects from "../../components/miscellaneous/floating-search/redux/reducer/getAllSubjects";
import getAllQuizSubjects from "../../components/miscellaneous/floating-search/redux/reducer/getAllQuizSubjects";
import getRecentSearch from "../../components/miscellaneous/floating-search/redux/reducer/getRecentSearch";
import floatingSearchBoxShown from "../../components/miscellaneous/floating-search/redux/reducer/floatingSearchBoxShown";
import tostMessageBoxShown from "../../components/miscellaneous/tost-message/redux/reducer/tostMessageBoxShown";
import tostMessageType from "../../components/miscellaneous/tost-message/redux/reducer/tostMessageType";
import tostMessageText from "../../components/miscellaneous/tost-message/redux/reducer/tostMessageText";
import getUserPermissions from "../../components/miscellaneous/floating-search/redux/reducer/getUserPermissions";
import getQuestionsBySubjectName from "../../components/quiz-management/subject-section/redux/reducer/getQuestionsBySubjectName";
import getQuestionsBySubjectNameMobile from "../../components/quiz-management/subject-section-mobile/redux/reducer/getQuestionsAnswersBySubjectName";
import getAllArchives from "../../components/archive-and-deleted-data/archive-list-section/redux/reducer/getAllArchives";
import getAllDeletedList from "../../components/archive-and-deleted-data/deleted-list-section/redux/reducer/getAllDeletedList";
import getIsUserAlreadyLoggedIn from "../../components/authentication-and-user-management/auth/redux/reducer/getIsUserAlreadyLoggedIn";
import getAllDeletedQuizList from "../../components/archive-and-deleted-data/deleted-quiz-list-section/redux/reducer/getAllDeletedQuizList";

export default combineReducers({
    getSubjectTypes,
    getQuizSubjectTypes,
    getAllSubjects,
    getAllQuizSubjects,
    getRecentSearch,
    floatingSearchBoxShown,
    tostMessageBoxShown,
    tostMessageType,
    tostMessageText,
    getUserPermissions,
    getQuestionsBySubjectName,
    getAllArchives,
    getAllDeletedList,
    getIsUserAlreadyLoggedIn,
    getQuestionsBySubjectNameMobile,
    getAllDeletedQuizList
})