import React, { useCallback, useState } from "react";

import { demoAddMdxValue } from "../../../../util/db";
import MdEditor from "../../../miscellaneous/re-usable-components/md-editor/MdEditor";
import Select from "../../re-usable-components/select/Select";
import WhiteBackgroundLayout from "../../re-usable-components/layout/WhiteBackgroundLayout";
import IconContainer from "../../re-usable-components/icon-container/IconContainer";
import SvgIcon from "../../../../Icons/SvgIcon";
import { footerList } from "../../../../constants/footer-list";

const AddMdxValue = () => {
  const [mdxValue, setMdxValue] = useState("");
  const [footerContentName, setFooterContentName] = useState("");

  const onChangeQsn = useCallback((value) => {
    setMdxValue(value);
  }, []);

  const handelChange = (event) => {
    setFooterContentName(event.target.value);
  };

  const handelSave = useCallback(() => {
    demoAddMdxValue({ docName: footerContentName, value: mdxValue })
      .then((response) => {
        alert(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },[mdxValue, footerContentName]);

  return (
    <WhiteBackgroundLayout>
      <div className="block sm:flex sm:items-center sm:justify-between border-b pb-4">
        <div className="flex items-center">
          <IconContainer onClick={() => {}}>
            <SvgIcon iconName="duo-arrow-left" sizeName="xl" />
          </IconContainer>
          <div className="sm:flex-auto pl-2">
            <h1 className="text-2xl sm:text-3xl font-medium">Footer content</h1>
          </div>
        </div>
      </div>
      <div className="mt-6 px-3 sm:px-4 pb-4">
        <div className="w-full">
          <div className="w-full mb-6">
            <Select
              label={"Content name"}
              id="contentName"
              options={footerList}
              name={"contentName"}
              value={footerContentName}
              onChange={handelChange}
            />
          </div>
          <div className="mx-auto divide-y divide-gray-900/10">
            <MdEditor value={mdxValue} onChange={onChangeQsn} />
            <button
              onClick={handelSave}
              type="submit"
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </WhiteBackgroundLayout>
  );
};

export default AddMdxValue;
