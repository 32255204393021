import { useEffect, useState } from "react";

import { getAllSubjectTypes } from "../../../../util/db";

const useGetAllSubjectsTypes = () => {
  const [subjectTypes, setSubjectTypes] = useState([]);
  const [status, setStatus] = useState("");

  useEffect(() => {
      getAllSubjectTypes()
        .then((res) => {
          if (res.status === "success") {
            setSubjectTypes([...res.data]);
            setStatus(res.status);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  return { subjectTypes, status };
};

export default useGetAllSubjectsTypes;
