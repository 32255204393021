import { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";

import { getFaqList } from "../../../../util/db";
import MdxFunctionCompiler from "../../../../util/mdxFunctionCompiler";
import Layout from "../../../miscellaneous/re-usable-components/layout/Layout";
import { useAppContext } from "../../../../context/AppContext";
import SvgIcon from "../../../../Icons/SvgIcon";

const FaqSection = () => {
  const [faqQuestion, setFaqQuestion] = useState([]);
  const [isFaqListLoading, setIsFaqListLoading] = useState(true);
  const { isDarkTheme } = useAppContext();

  useEffect(() => {
    getFaqList()
      .then((response) => {
        if (response.status === "success") {
          setFaqQuestion(response.data);
          setIsFaqListLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Layout hScreen={"h-screen"} sectionName={"FAQ"}>
      <div className="mx-auto max-w-7xl px-6 py-4 sm:py-16 lg:px-8">
        <div className="mx-auto divide-y">
          <h2 className={`text-2xl font-bold leading-10 tracking-tight ${isDarkTheme ? `text-white` : `text-gray-900`}`}>
            Frequently asked questions
          </h2>
          <dl className={`mt-10 space-y-6 divide-y ${isDarkTheme ? `divide-slate-300` : `divide-gray-900/10`}`}>
            {!isFaqListLoading &&
              faqQuestion.map((faq, index) => (
                <Disclosure as="div" key={index} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt>
                        <Disclosure.Button className={`flex w-full items-start justify-between text-left ${isDarkTheme ? `text-white` : `text-gray-900`}`}>
                          <span className="text-base font-semibold leading-7">
                            <MdxFunctionCompiler jsxFunction={faq.question} />
                          </span>
                          <span className="ml-6 flex h-7 items-center">
                            {open ? (
                              <SvgIcon iconName="duo-minus" sizeName="lg" />
                            ) : (
                              <SvgIcon iconName="duo-plus" sizeName="lg" />
                            )}
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <MdxFunctionCompiler jsxFunction={faq.answer} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
          </dl>
        </div>
      </div>
    </Layout>
  );
};

export default FaqSection;
