import * as actionType from "../actions/actionTypes";

const initialState = {
    subjectTypes: []
}

export default function getSubjectTypes(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_SUBJECT_TYPES: {
            return {
                ...state,
                subjectTypes: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}