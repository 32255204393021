import * as actionType from "../action/actionTypes";

const initialState = {
    allArchives: []
}

export default function getAllArchives(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_ALL_ARCHIVES: {
            return {
                ...state,
                allArchives: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}