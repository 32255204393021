import { put, call, takeEvery } from "redux-saga/effects";
import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* getQuestionsAnswersBySubjectNameSaga(){
    yield takeEvery(actionType.GET_QUESTIONS_ANSWERS_BY_SUBJECT_NAME_FOR_MOBILE, fetchQuestionsBySubjectName);
}

function* fetchQuestionsBySubjectName(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_QUESTIONS_ANSWERS_BY_SUBJECT_NAME_FOR_MOBILE, payload: response})
    } catch (error) {
        console.log(error);
    }
}