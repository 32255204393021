import * as actionType from "../action/actionTypes";

const initialState = {
    isUserAlreadyLoggedIn: false
}

export default function getIsUserAlreadyLoggedIn(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_IS_USER_ALREADY_LOGGED_IN: {
            return {
                ...state,
                isUserAlreadyLoggedIn: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}