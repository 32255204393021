import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";
import SetFooterContentSection from "../../components/authentication-and-user-management/admin-section/view/SetFooterContentSection.js";

function SetFooterContentPage(props) {
  return (
    <>
      <Meta title="Set footer content" />
      <SetFooterContentSection />
    </>
  );
}

export default requireAuth(SetFooterContentPage);