import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const ClearCacheSection = lazy(() => import('../../components/logs-and-cache-management/clear-cache-section/view/ClearCacheSection'));

function ClearCachePage(props) {
  return (
    <>
      <Meta title="Cache clean" />
      <Suspense fallback={<div>Loading...</div>}>
        <ClearCacheSection />
      </Suspense>
    </>
  );
}

export default requireAuth(ClearCachePage);
