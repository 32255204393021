import React from "react";
import "../../styles/global.css";
import Navbar from "../../components/miscellaneous/navbar/view/Navbar";
import IndexPage from "./index";
import AboutPage from "../content-and-information/about";
import PricingPage from "../content-and-information/pricing";
import SubjectPage from "../quiz-management/subject";
import AdminPage from "../authentication-and-user-management/admin";
import ManageClaimsPage from "./manage-claims";
import AuthPage from "../authentication-and-user-management/auth";
import DashboardPage from "./dashboard";
import SubjectsPage from "../quiz-management/subjects";
import SettingsPage from "./settings";
import ArchivedListPage from './archive-list';
import DeletedListPage from './deleted-list';
import UsersListPage from "../authentication-and-user-management/users-list";
import LegalPage from "../content-and-information/legal";
import { Routes, Route,  BrowserRouter } from "../../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "../content-and-information/404";
import "../../util/analytics";
import Chat from "../../components/miscellaneous/chat/view/Chat";
import { AuthProvider } from "../../util/auth";
import { QueryClientProvider } from "../../util/db";
import FloatingSearch from "../../components/miscellaneous/floating-search/view/FloatingSearch";
import TostMessage from "../../components/miscellaneous/tost-message/view/TostMessage";
import AddQuestionsForUsers from "./add-questions-for-users";
import FaqPage from "../content-and-information/faq";
import TermsPage from "../content-and-information/terms";
import ApiLogsPage from "./api-logs";
import RootLayout from "../../components/miscellaneous/re-usable-components/layout/RootLayout";
import Footer from "../../components/miscellaneous/footer/view/Footer";
import AllDeviceLogOutModalBox from "../../components/authentication-and-user-management/auth/components/AllDeviceLogOutModalBox";
import ClearCachePage from "./clear-cache";
import QuizSubjectsPage from "../quiz-management/quiz-subjects";
import QuizSettingsPage from "../quiz-management/quiz-settings";
import QuizzesListPage from "../quiz-management/quizzes-list";
import AddQuizPage from "../quiz-management/add-quiz";
import QuizzesPage from "../quiz-management/quizzes";
import DeletedQuizListPage from "../quiz-management/deleted-quiz-list";
import SupportPage from "../support-management/support";
import ManageSupportPage from "../support-management/manage-support";
import IssueListPage from "../support-management/issue-list";
import AgentListPage from "../support-management/agent-list";
import ManageAgentPage from "../support-management/manage-agent";
import AssignIssueListPage from "../support-management/assign-issue-list";
import ViewIssuePage from "../support-management/view-issue";
import DiscussPage from "./discuss";
import AppContextProvider from "../../context/AppContext";
import PrivacyPolicyPage from "../content-and-information/privacy-policy";
import RefundPolicyPage from "../content-and-information/refund-policy";
import SetFooterContentPage from "../content-and-information/set-footer-content";
import AccessDeniedPage from "../content-and-information/access-denied";
import QuizSurveyEditorPage from "../quiz-management/quiz-survey-editor";
import PrivateRoute from "../Components/PrivateRoute";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <AppContextProvider>
        <Chat />
        <BrowserRouter>
          <>
            <Navbar />
              <FloatingSearch/>
              <TostMessage/>
              <RootLayout>
                <Routes>
                  <Route exact path="/" element={<IndexPage />} />

                  <Route exact path="/about" element={<AboutPage />} />

                  <Route exact path="/pricing" element={<PricingPage />} />

                  <Route exact path="/auth/:type" element={<AuthPage />} />

                  <Route exact path="/subjects" element={<SubjectsPage />} />

                  <Route exact path="/quiz-subjects" element={<QuizSubjectsPage />} />

                  <Route exact path="/subjects/:subject" element={<SubjectPage />} />

                  <Route exact path="/faq" element={<FaqPage />} />

                  <Route exact path="/terms-of-service" element={<TermsPage />} />

                  <Route exact path="/support" element={<SupportPage />} />

                  <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />

                  <Route exact path="/log-out-from-all-device" element={ <AllDeviceLogOutModalBox /> } />

                  <Route exact path="/clear-cache" element={ <ClearCachePage /> } />
                  
                  <Route exact path="/settings/:section" element={<SettingsPage />} />

                  <Route exact path="/legal/:section" element={<LegalPage />} />

                  <Route exact path="/purchase/:plan" element={<PurchasePage />} />

                  <Route exact path="/quiz-editor" element={<QuizSurveyEditorPage />} />

                  <Route exact path="/dashboard" element={
                    <PrivateRoute permissions={["isBasicPlan", "isFreeUser"]} >
                      <DashboardPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/quizzes/:subject" element={
                    <PrivateRoute permissions={["isBasicPlan"]} >
                      <QuizzesPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/add-question" element={
                    <PrivateRoute permissions={["isAdvancePlan"]} >
                      <AddQuestionsForUsers />
                    </PrivateRoute>
                  } />



                  <Route exact path="/cancellation" element={<RefundPolicyPage />} />

                  <Route exact path="/admin/users-list" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <UsersListPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/add-question" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <AdminPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/manage-claims" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <ManageClaimsPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/api-logs" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <ApiLogsPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/manage-support" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <ManageSupportPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/issue-list" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <IssueListPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/agent-list" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <AgentListPage />
                    </PrivateRoute>
                  } /> 
                  
                  <Route exact path="/admin/discuss" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <DiscussPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/manage-agent" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <ManageAgentPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/admin/set-footer-content" element={
                    <PrivateRoute permissions={["isSuperAdmin"]} >
                      <SetFooterContentPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/archived-list" element={
                    <PrivateRoute permissions={["isAdvancePlan", "isFreeUser"]} >
                      <ArchivedListPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/deleted-list" element={
                    <PrivateRoute permissions={["isAdvancePlan"]} >
                      <DeletedListPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/quiz-settings" element={
                    <PrivateRoute permissions={["isBasicPlan"]} >
                      <QuizSettingsPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/quizzes-list" element={
                    <PrivateRoute permissions={["isFreeUser", "isBasicPlan"]} >
                      <QuizzesListPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/add-quiz" element={
                    <PrivateRoute permissions={["isBasicPlan"]} >
                      <AddQuizPage />
                    </PrivateRoute>
                  } />

                  <Route exact path="/deleted-quiz-list" element={
                    <PrivateRoute permissions={["isBasicPlan"]} >
                      <DeletedQuizListPage />
                    </PrivateRoute>
                  } />

                  
                  <Route exact path="/assign-issue-list" element={
                    <PrivateRoute permissions={["isBasicPlan"]} >
                      <AssignIssueListPage />
                    </PrivateRoute>
                  } />
                      
                  <Route exact path="/agent/view-issue" element={
                    <PrivateRoute permissions={["isAgent"]} >
                      <ViewIssuePage />
                    </PrivateRoute>
                  } />

                  <Route
                    exact
                    path="/firebase-action"
                    element={<FirebaseActionPage />}
                  />

                  <Route path="/unauthorized" element={<AccessDeniedPage />} />

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </RootLayout>
              <Footer />
          </>
        </BrowserRouter>
        </AppContextProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
