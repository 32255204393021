import * as actionType from "../action/actionTypes";

const initialState = {
    recentSearch: []
}

export default function getRecentSearch(state = initialState, action) {

    switch (action.type) {

        case actionType.GOT_RECENT_SEARCH: {
            return {
                ...state,
                recentSearch: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
    
}