import * as actionType from "../action/actionTypes";

const initialState = {
    isTostMessageBoxShown: false
}

export default function tostMessageBoxShown(state = initialState, action) {

    switch (action.type) {

        case actionType.TOST_MESSAGE_BOX_SHOWN: {
            return {
                ...state,
                isTostMessageBoxShown: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
    
}