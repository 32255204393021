import React from "react";

const ContentSkeleton = ({ paddingY = "", marginX= "" }) => {
  let qsnSkeleton = Array(5).fill("");
  
  return (
    <div className="animate-pulse flex">
      <div className="flex-1 space-y-3 py-8">
        {qsnSkeleton.map((e, i) => (
          <div
            className={`h-8 bg-slate-300 rounded ${paddingY} ${marginX}`}
            key={i + e}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ContentSkeleton;
