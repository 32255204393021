import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const AddQuizSection = lazy(() => import('../../components/quiz-management/add-quiz-section/view/AddQuizSection'));

function AddQuizPage(props) {

  return (
    <>
      <Meta title="Add quiz" />
      <Suspense fallback={<div>Loading...</div>}>
        <AddQuizSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AddQuizPage);