import { useCallback, useRef } from "react";
import { logOutFromAllDevices } from "./db";

export const useLofOutFromAll = () => {
  const isLoggedOutFromAllDevice = useRef(true);

  const handelLogOutFromAll = useCallback(() => {
    const response = logOutFromAllDevices()
      .then(() => {
        return isLoggedOutFromAllDevice.current;
      })
      .catch((error) => {
        console.log(error);
        isLoggedOutFromAllDevice.current = false;
        return isLoggedOutFromAllDevice.current;
      });
    return response;
    // eslint-disable-next-line
  }, []);

  return { handelLogOutFromAll };
};
