import { call, put, takeEvery } from "redux-saga/effects";

import * as actionType from "../action/actionTypes";
import { dataPromise } from "../../../../../util/db";

export default function* tostMessageBoxShownSaga() {
  yield takeEvery(
    actionType.TOST_MESSAGE_BOX_HIDDEN,
    handelTostMessageBoxShownSaga
  );
}

function* handelTostMessageBoxShownSaga(action) {
  try {
    const response = yield call(dataPromise, action.payload);
    yield put({ type: actionType.TOST_MESSAGE_BOX_SHOWN, payload: response });
  } catch (error) {
    console.log(error);
  }
}
