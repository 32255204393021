import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";

const SupportSection = lazy(() => import('../../components/support-management/support-section/view/SupportSection'));

function SupportPage(props) {

  return (
    <>
      <Meta title="Support" />
      <Suspense fallback={<div>Loading...</div>}>
        <SupportSection />
      </Suspense>
    </>
  );
}

export default SupportPage;
