import * as actionType from "../action/actionTypes";

const initialState = {
    deletedList: []
}

export default function getAllDeletedQuizList(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_DELETED_QUIZ_LIST: {
            return {
                ...state,
                deletedList: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}