import { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Transition } from '@headlessui/react';

import * as actionTypeTostMessage from "../redux/action";
import SvgIcon from '../../../../Icons/SvgIcon';


function TostMessage(props) {

  const { isTostMessageBoxShown, actions, tostMessageType, tostMessageText} = props

  useEffect(()=>{
    if(isTostMessageBoxShown){
      setTimeout(()=>{
        actions.tostMessageBoxShown(false);
        actions.tostMessageText("");
        actions.tostMessageType("");
      },2000)
  }
  },[actions, isTostMessageBoxShown]);

  const tostMessageIcon = (tostMessageType) => {
    switch (tostMessageType) {
      case "success":
        return (<SvgIcon iconName="duo-circle-check" sizeName="lg" className="fill-green-400" />);
      case "info":
        return (<SvgIcon iconName="duo-triangle-exclamation" sizeName="lg" className="fill-yellow-400" />);
      case "error":
        return (<SvgIcon iconName="duo-triangle-exclamation" sizeName="lg" className="fill-red-400" />);
      default:
        break;
    }
  };

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed z-10 inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={isTostMessageBoxShown}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {tostMessageIcon(tostMessageType)}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {tostMessageText}
                    </p>
                    {/* <p className="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p> */}
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        actions.tostMessageBoxShown(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <SvgIcon iconName="duo-x-mark" sizeName="lg" className='fill-gray-600' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return ({
    isTostMessageBoxShown: state.tostMessageBoxShown.isTostMessageBoxShown,
    tostMessageType: state.tostMessageType.tostMessageType,
    tostMessageText: state.tostMessageText.tostMessageText
  })
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    tostMessageBoxShown: (payload) =>{
      dispatch(actionTypeTostMessage.tostMessageBoxShown(payload));
    },
    tostMessageText: (payload) =>{
      dispatch(actionTypeTostMessage.tostMessageText(payload));
    },
    tostMessageType: (payload) =>{
      dispatch(actionTypeTostMessage.tostMessageType(payload));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TostMessage);