import { useEffect, useState } from "react";

import { getAllQuizSubjectTypes } from "../../../../util/db";

const useGetAllQuizSubjectTypes = () => {
  const [quizSubjectTypesData, setQuizSubjectTypes] = useState([]);
  const [status, setStatus] = useState("");

  useEffect(() => {
      getAllQuizSubjectTypes()
        .then((res) => {
          if (res.status === "success") {
            setQuizSubjectTypes([...res.data]);
            setStatus(res.status);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  return { quizSubjectTypesData, status };
};

export default useGetAllQuizSubjectTypes;
