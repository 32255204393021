import { put, call, takeEvery } from "redux-saga/effects";

import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* getAllArchivesSaga(){
    yield takeEvery(actionType.GET_ALL_ARCHIVES, fetchGetAllArchives);
}

function* fetchGetAllArchives(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_ALL_ARCHIVES, payload: response})
    } catch (error) {
        console.log(error);
    }
}