import * as actionType from "../action/actionTypes";

const initialState = {
    questions: []
}

export default function getQuestionsBySubjectName(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_QUESTIONS_BY_SUBJECT_NAME: {
            return {
                ...state,
                questions: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}