import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "./../../util/auth";

const ManageSupportSection = lazy(() => import('../../components/authentication-and-user-management/admin-section/view/ManageSupportSection'));

function ManageSupportPage(props) {

  return (
    <>
      <Meta title="Manage Support" />
      <Suspense fallback={<div>Loading...</div>}>
        <ManageSupportSection />
        </Suspense>
    </>
  );
}

export default requireAuth(ManageSupportPage);
