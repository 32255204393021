import * as actionType from "../action/actionTypes";

const initialState = {
    allQuizSubjects: []
}

export default function getAllQuizSubjects(state = initialState, action) {

    switch (action.type) {

        case actionType.GOT_ALL_QUIZ_SUBJECTS: {
            return {
                ...state,
                allQuizSubjects: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
    
}