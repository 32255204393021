import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const QuizSurveyEditorSection = lazy(() => import('../../components/quiz-management/quiz-survey-editor-section/view/QuizSurveyEditorSection'));

function QuizSurveyEditorPage(props) {

  return (
    <>
      <Meta title="Quiz edit quiz" />
      <Suspense fallback={<div>Loading...</div>}>
        <QuizSurveyEditorSection />
      </Suspense>
    </>
  );
}

export default requireAuth(QuizSurveyEditorPage);