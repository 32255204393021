import { Fragment, useCallback, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as actionTypeLoggedInInfo from "../redux/action";
import { useLofOutFromAll } from "../../../../util/useLogOutFromAll";
import { useAuth } from "../../../../util/auth";
import { useLogInInfo } from "../../../../hooks/authentication-and-user-information/useLogInInfo";

const AllDeviceLogOutModalBox = ({ actions, isUserAlreadyLoggedIn = false }) => {
  const cancelButtonRef = useRef(null);
  const { handelLogOutFromAll } = useLofOutFromAll();
  const navigate = useNavigate();
  const auth = useAuth();
  const { handelLoginInfo } = useLogInInfo();

  const handelClose = useCallback(() => {
    handelLoginInfo("logout", false);
    auth.signout();
    actions.getIsUserAlreadyLoggedIn(false);
    // eslint-disable-next-line
  },[auth, actions]);

  const handelAllLogOut = () => {
    (async () => {
      const isLoggedOutFromAllDevice = await handelLogOutFromAll();
      if (isLoggedOutFromAllDevice) {
        handelClose();
        navigate("/auth/signin");
      }
    })();
  };

  return (
    <Transition.Root show={isUserAlreadyLoggedIn} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={()=>{}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {"You already loggedIn in another device."}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You can login here after logout from all device.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-evenly mt-5">
                  <div>
                    <button
                      type="button"
                      className="inline-flex sm:w-52 justify-center rounded-md border border-transparent bg-gray-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={handelClose}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="inline-flex sm:w-52 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={handelAllLogOut}
                    >
                      Log out from all devices
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    isUserAlreadyLoggedIn: state.getIsUserAlreadyLoggedIn.isUserAlreadyLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getIsUserAlreadyLoggedIn: (payload) => {
      dispatch(actionTypeLoggedInInfo.getIsUserAlreadyLoggedIn(payload));
    },
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllDeviceLogOutModalBox);
