import {createStore, compose, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import { getAllQuestionsSaga, getSubjectsSaga, getRecentSearchSaga, floatingSearchBoxShownSaga, tostMessageBoxShownSaga, tostMessageTextSaga, tostMessageTypeSaga, getUserPermissionsSaga, getQuestionsBySubjectNameSaga, getAllArchivesSaga, getAllDeletedListSaga, getIsUserAlreadyLoggedInSaga, getQuestionsAnswersBySubjectNameSaga, getQuizSubjectsSaga, getAllQuizSubjectsSaga, getAllDeletedQuizListSaga } from "./sagas";
import rootReducer from "./reducers/index"


const sagaMiddleware = createSagaMiddleware();

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middleWare = window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === "development"?
                    compose(applyMiddleware(sagaMiddleware), reduxDevTools):
                    applyMiddleware(sagaMiddleware);
                    
export const store = createStore(rootReducer, middleWare);

sagaMiddleware.run(getSubjectsSaga);
sagaMiddleware.run(getQuizSubjectsSaga);
sagaMiddleware.run(getAllQuizSubjectsSaga);
sagaMiddleware.run(getAllQuestionsSaga);
sagaMiddleware.run(getRecentSearchSaga);
sagaMiddleware.run(floatingSearchBoxShownSaga);
sagaMiddleware.run(tostMessageBoxShownSaga);
sagaMiddleware.run(tostMessageTypeSaga);
sagaMiddleware.run(tostMessageTextSaga);
sagaMiddleware.run(getUserPermissionsSaga);
sagaMiddleware.run(getQuestionsBySubjectNameSaga);
sagaMiddleware.run(getAllArchivesSaga);
sagaMiddleware.run(getAllDeletedListSaga);
sagaMiddleware.run(getIsUserAlreadyLoggedInSaga);
sagaMiddleware.run(getQuestionsAnswersBySubjectNameSaga);
sagaMiddleware.run(getAllDeletedQuizListSaga);