import * as actionType from "../action/actionTypes";

const initialState = {
  tostMessageText: "",
};

export default function tostMessageText(state = initialState, action) {
  switch (action.type) {
    case actionType.GOT_TOST_MESSAGE_TEXT: {
      return {
        ...state,
        tostMessageText: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}
