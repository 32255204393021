import * as actionType from "../action/actionTypes";

const initialState = {
    tostMessageType: "",
};

export default function tostMessageType(state = initialState, action) {
  switch (action.type) {
    case actionType.TOST_ERROR_MESSAGE: {
      return {
        ...state,
        tostMessageType: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
}
