import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const QuizzesSection = lazy(() => import('../../components/quiz-management/quizzes-section/view/QuizzesSection'));

function QuizzesPage(props) {
  
  return (
    <>
      <Meta title="Quizzes" />
      <Suspense fallback={<div>Loading...</div>}>
        <QuizzesSection />
      </Suspense>
    </>
  );
}

export default requireAuth(QuizzesPage);