import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const QuizzesListSection = lazy(() => import('../../components/quiz-management/quizzes-list-section/view/QuizzesListSection'));

function QuizzesListPage(props) {
  return (
    <>
      <Meta title="Quiz list" />
      <Suspense fallback={<div>Loading...</div>}>
        <QuizzesListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(QuizzesListPage);