import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const ApiLogsSection = lazy(() => import('../../components/logs-and-cache-management/api-logs-section/view/ApiLogsSection'));

function ApiLogsPage(props) {
  return (
    <>
      <Meta title="Users" />
      <Suspense fallback={<div>Loading...</div>}>
        <ApiLogsSection />
      </Suspense>
    </>
  );
};

export default requireAuth(ApiLogsPage);
