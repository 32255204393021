import React from "react";
import FaqSection from "../../components/content-and-information-pages/faq-section/view/FaqSection";
import Meta from "../../components/meta-and-theme/meta/view/Meta";

function FaqPage(props) {

  return (
    <>
      <Meta title="FAQ" />
      <FaqSection />
    </>
  );
}

export default FaqPage;
