import * as actionType from "../action/actionTypes";

const initialState = {
  permissions: {},
};

export default function getUserPermissions(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_USER_PERMISSIONS: {
            return {
                ...state,
                permissions: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
    
}