import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const ViewIssueSection = lazy(() => import('../../components/support-management/assign-issue-list-section/view/ViewIssueSection'));

function ViewIssuePage(props) {

  return (
    <>
      <Meta title="Manage Support" />
      <Suspense fallback={<div>Loading...</div>}>
        <ViewIssueSection />
      </Suspense>
    </>
  );
}

export default requireAuth(ViewIssuePage);
