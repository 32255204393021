import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import PrivacyPolicySection from "../../components/content-and-information-pages/privacy-policy/view/PrivacyPolicySection";

function PrivacyPolicyPage(props) {
  return (
    <>
      <Meta title="Privacy Policy" />
      <PrivacyPolicySection />
    </>
  );
}

export default PrivacyPolicyPage;
