import { put, call, takeEvery } from "redux-saga/effects";
import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* getQuestionsBySubjectNameSaga(){
    yield takeEvery(actionType.GET_QUESTIONS_BY_SUBJECT_NAME, fetchQuestionsBySubjectName);
}

function* fetchQuestionsBySubjectName(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_QUESTIONS_BY_SUBJECT_NAME, payload: response})
    } catch (error) {
        console.log(error);
    }
}