import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const QuizSubjectsSubjectsSection = lazy(() => import('../../components/quiz-management/quiz-subjects-section/view/QuizSubjectsSubjectsSection'));

function QuizSubjectsPage(props) {
  return (
    <>
      <Meta title="Quiz Subjects" />
      <Suspense fallback={<div>Loading...</div>}>
        <QuizSubjectsSubjectsSection />
      </Suspense>
    </>
  );
}

export default requireAuth(QuizSubjectsPage);