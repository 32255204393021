import React, { useEffect, useState } from "react";

import Layout from "../../../miscellaneous/re-usable-components/layout/Layout";
import ContentSkeleton from "../../../miscellaneous/re-usable-components/content-skeleton/ContentSkeleton";
import { getRefundPolicy } from "../../../../util/db";
import MdxFunctionCompiler from "../../../../util/mdxFunctionCompiler";

const RefundPolicySection = () => {
  const [refundContent, setRefundContent] = useState();
  const [isContentLoading, setIsContentLoading] = useState(true);

  const replaceWithEnvData = (value) => {
    if (!value) return;
    value = value.replaceAll("[app.name]", process.env.REACT_APP_APP_NAME);
    value = value.replaceAll(
      "[support-email]",
      process.env.REACT_APP_SUPPORT_EMAIL
    );
    value = value.replaceAll(
      "[Customer Support]",
      process.env.REACT_APP_CUSTOMER_SUPPORT
    );
    return value;
  };

  useEffect(() => {
    setIsContentLoading(true);
    getRefundPolicy()
      .then((response) => {
        if (response.status === "success") {
          setRefundContent(response.value);
          setIsContentLoading(false);
        }
      })
      .catch((error) => {
        setIsContentLoading(true);
        console.log(error);
      });
  }, []);

  return (
    <Layout hScreen={"h-screen"} sectionName={"Refund Policy"}>
      <div className="mx-auto max-w-7xl px-6 py-4 sm:py-1 lg:py-1 lg:px-8">
        <div className="mx-auto divide-y divide-gray-900/10">
          <dl className="my-10 space-y-6 divide-y divide-gray-900/10">
            {isContentLoading && <ContentSkeleton />}
            {!isContentLoading && (
              <div id="refund" className="default-style decimal">
                <MdxFunctionCompiler
                  jsxFunction={replaceWithEnvData(refundContent)}
                />
              </div>
            )}
          </dl>
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicySection;
