import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Combobox, Dialog, Transition } from '@headlessui/react';

import * as actionType from "../../../../redux/actions";
import * as actionTypeSearch from "../redux/action";
import { useAuth } from '../../../../util/auth';
import { useSearchController } from '../../../../hooks/utilities-and-helpers/useSearchController';
import { storeInLocal } from '../../../../util/storeInLocal';
import useAllSubjectQuestions from '../../../../hooks/query-and-data-management/useAllSubjectQuestions';
import useAddQuery from '../../../../hooks/query-and-data-management/useAddQuery';
import useInternetConnectivityChecker from '../../../../hooks/utilities-and-helpers/useInternetConnectivityChecker';
import ComboBoxOption from '../components/ComboBoxOption';
import BottomHelperDoc from '../components/BottomHelperDoc';
import NoResultFound from '../components/NoResultFound';
import AllDeviceLogOutModalBox from '../../../authentication-and-user-management/auth/components/AllDeviceLogOutModalBox';
import useGetAllSubjectsTypes from '../hooks/useGetAllSubjectTypes';
import useGetAllQuizSubjectTypes from '../hooks/useGetAllQuizSubjectTypes';
import SvgIcon from '../../../../Icons/SvgIcon';
import { useAppContext } from '../../../../context/AppContext';

function FloatingSearch(props) {
  const { questionsType, actions, allSubjects, recentSearch, isFloatingSearchBoxShown, quizSubjectTypes, allQuizSubjects } = props;
  const [rawQuery, setRawQuery] = useState('')
  const query = rawQuery.toLowerCase().replace(/^[#>]/, '');
  const auth = useAuth();
  const { isDarkTheme } = useAppContext();
  const [uid, setUid] = useState("");

  const { status, subjectTypes } = useGetAllSubjectsTypes();
  const { status: quizSubjectStatus, quizSubjectTypesData } = useGetAllQuizSubjectTypes();
  useSearchController(actions.getRecentSearch,actions.floatingSearchBoxShown);
  const navigate = useNavigate();
  const { allSubjectQsn } = useAllSubjectQuestions(uid);
  const { addQuery } = useAddQuery();
  useInternetConnectivityChecker()

  const filteredSubjects =
    rawQuery === '#'
      ? allSubjects
      : query === '' || rawQuery.startsWith('>') || rawQuery.startsWith('?')
      ? []
      : allSubjects.filter((subject) => subject.name.toLowerCase().includes(query))

  const filteredQuizSubjects =
      rawQuery === '='
        ? allQuizSubjects
        : query === '' || rawQuery.startsWith('>') || rawQuery.startsWith('?')
        ? []
        : allQuizSubjects.filter((subject) => subject.name.toLowerCase().includes(query))

  const filteredAllQuestion =
    rawQuery === '>'
      ? allSubjectQsn
      : query === '' || rawQuery.startsWith('#') || rawQuery.startsWith('?')
      ? []
      : allSubjectQsn.filter((user) => user.name.toLowerCase().includes(query));

  const pageChange = (item)=>{
    actions.floatingSearchBoxShown(false);
    if(item && item.subject){
      addQuery(`/subjects/${item.subject}`,"qId",`${item.id}`);
    }else{
      navigate(`/subjects/${item.name}`);
    }
    storeInLocal(item);
  }

  useEffect(() => {
    if (auth && auth.user) {
      setUid(auth.user.id);
    }
  }, [auth]);

  useEffect(() => {
    if (status === "success" && subjectTypes.length) {
      actions.getSubjectTypes(subjectTypes)
    }
  }, [status, subjectTypes, actions]);

  useEffect(() => {
    if (questionsType.length) {
      let qsnList = [];
      return questionsType.forEach((x) => {
        qsnList.push(...x.children);
        actions.getAllSubjects([...qsnList]);
      });
    }
  }, [questionsType, actions]);


  useEffect(() => {
    if (quizSubjectStatus === "success" && quizSubjectTypesData.length) {
      actions.getQuizSubjectTypes(quizSubjectTypesData)
    }
  }, [quizSubjectStatus, quizSubjectTypesData, actions]);

  useEffect(() => {
    if (quizSubjectTypes.length) {
      let subjectList = [];
      return quizSubjectTypes.forEach((x) => {
        subjectList.push(...x.children);
        actions.getAllQuizSubjects([...subjectList]);
      });
    }
  }, [quizSubjectTypes, actions]);

  return (
    <>
    <Transition.Root show={isFloatingSearchBoxShown} as={Fragment} afterLeave={() => setRawQuery('')} appear>
      <Dialog as="div" className="relative z-10" onClose={actions.floatingSearchBoxShown}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={`mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 transition-all ${isDarkTheme ? `bg-[#333333]` : `bg-white`}`}>
              <Combobox onChange={(item)=>pageChange(item)}>
                <div className="relative">
                  <SvgIcon iconName="duo-magnifying-glass" sizeName="lg" className='pointer-events-none absolute top-3.5 left-4 h-5 w-5' />
                  <Combobox.Input
                    className={`h-12 w-full border-0 bg-transparent pl-11 pr-4 placeholder-gray-400 focus:ring-0 sm:text-sm focus-visible:outline-none ${isDarkTheme ? `text-white` : `text-gray-800`}`}
                    placeholder="Search..."
                    onChange={(event) => setRawQuery(event.target.value)}
                  />
                </div>

                {(filteredSubjects.length > 0 || filteredAllQuestion.length > 0 || recentSearch.length > 0 || filteredQuizSubjects.length > 0) && (
                  <Combobox.Options
                    static
                    className="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                  >
                    
                    {recentSearch.length > 0  && !rawQuery && (
                      <li>
                        <h2 className={`text-xs font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Recent Search</h2>
                        <ul className={`-mx-4 mt-2 text-sm ${isDarkTheme ? `text-white` : `text-gray-700`}`}>
                          {recentSearch.map((recentSearch, i) => (
                            <ComboBoxOption 
                              each={recentSearch} 
                              to={recentSearch.id?`/subjects/${recentSearch.subject}?qId=${recentSearch.id}`:`/subjects/${recentSearch.name}`}
                              key={i} />
                          ))}
                        </ul>
                      </li>
                    )}

                    {filteredQuizSubjects.length > 0 && (
                      <li>
                        <h2 className={`text-xs font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Quiz Subjects</h2>
                        <ul className={`-mx-4 mt-2 text-sm ${isDarkTheme ? `text-white` : `text-gray-700`}`}>
                          {filteredQuizSubjects.map((subject) => (
                            <ComboBoxOption each={subject} to={`/quiz/${subject.name}`} key={subject.id} />
                          ))}
                        </ul>
                      </li>
                    )}

                    {filteredSubjects.length > 0 && (
                      <li>
                        <h2 className={`text-xs font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Subjects</h2>
                        <ul className={`-mx-4 mt-2 text-sm ${isDarkTheme ? `text-white` : `text-gray-700`}`}>
                          {filteredSubjects.map((subject) => (
                            <ComboBoxOption each={subject} to={`/subjects/${subject.name}`} key={subject.id} />
                          ))}
                        </ul>
                      </li>
                    )}

                    {filteredAllQuestion.length > 0 && (
                      <li>
                        <h2 className={`text-xs font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Questions</h2>
                        <ul className={`-mx-4 mt-2 text-sm ${isDarkTheme ? `text-white` : `text-gray-700`}`}>
                          {filteredAllQuestion.map((question) => (
                              <ComboBoxOption 
                                each={question} 
                                to={`/subjects/${question.subject}?qId=${question.id}`} 
                                key={question.id}
                              />
                          ))}
                        </ul>
                      </li>
                    )}
                  </Combobox.Options>
                )}

                {rawQuery === '?' && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <SvgIcon iconName="duo-life-ring" sizeName="xl" className="mx-auto" />
                    <p className={`mt-4 font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Help with searching</p>
                    <p className={`mt-2 ${isDarkTheme ? `text-slate-300` : `text-gray-500`}`}>
                      Use this tool to quickly search for users and projects across our entire platform. You can also
                      use the search modifiers found in the footer below to limit the results to just users or projects.
                    </p>
                  </div>
                )}

                {query !== '' && rawQuery !== '?' && filteredSubjects.length === 0 && filteredAllQuestion.length === 0 && filteredQuizSubjects.length === 0 && (
                 <NoResultFound/>
                )}

               <BottomHelperDoc rawQuery={rawQuery} />
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    <AllDeviceLogOutModalBox />
    </>
  )
}

const mapStateToProps = (state) => {
  return ({
    quizSubjectTypes: state.getQuizSubjectTypes.quizSubjectTypes,
    questionsType: state.getSubjectTypes.subjectTypes,
    allSubjects: state.getAllSubjects.allSubjects,
    allQuizSubjects: state.getAllQuizSubjects.allQuizSubjects,
    recentSearch: state.getRecentSearch.recentSearch,
    isFloatingSearchBoxShown: state.floatingSearchBoxShown.isFloatingSearchBoxShown,
    permissions: state.getUserPermissions.permissions
  })
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getSubjectTypes: (payload) => {
          dispatch(actionType.getSubjectTypes(payload));
      },
    getAllSubjects: (payload) =>{
          dispatch(actionTypeSearch.getAllSubjects(payload));
    },
    getAllQuizSubjects: (payload) =>{
      dispatch(actionTypeSearch.getAllQuizSubjects(payload));
    },
    getRecentSearch: (payload) =>{
          dispatch(actionTypeSearch.getRecentSearch(payload));
    },
    floatingSearchBoxShown: (payload) =>{
      dispatch(actionTypeSearch.floatingSearchBoxShown(payload));
    },
    getUserPermissions: (payload) =>{
      dispatch(actionTypeSearch.getUserPermissions(payload));
    },
    getQuizSubjectTypes: (payload) => {
      dispatch(actionType.getQuizSubjectTypes(payload));
    },
  },
});

export default  connect(mapStateToProps, mapDispatchToProps)(FloatingSearch)