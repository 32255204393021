import { put, call, takeEvery } from "redux-saga/effects";
import { dataPromise } from "../../util/db";
import * as actionType from "../actions/actionTypes";

export default function* getSubjectsSaga(){
    yield takeEvery(actionType.GET_SUBJECT_TYPES, fetchQuestions);
}

function* fetchQuestions(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_SUBJECT_TYPES, payload: response})
    } catch (error) {
        console.log(error);
    }
}