import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const ManageAgentSection = lazy(() => import('../../components/support-management/manage-agent-list-section/view/ManageAgentSection'));

function AgentListPage(props) {
  return (
    <>
      <Meta title="Manage agent" />
      <Suspense fallback={<div>Loading...</div>}>
        <ManageAgentSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AgentListPage);