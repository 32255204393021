import { call, put, takeEvery } from "redux-saga/effects";

import * as actionType from "../action/actionTypes";
import { dataPromise } from "../../../../../util/db";

export default function* tostMessageTypeSaga() {
  yield takeEvery(
    actionType.TOST_ERROR_MESSAGE,
    handelTostMessageTypeSaga
  );
}

function* handelTostMessageTypeSaga(action) {
  try {
    const response = yield call(dataPromise, action.payload);
    yield put({ type: actionType.TOST_MESSAGE, payload: response });
  } catch (error) {
    console.log(error);
  }
}
