import { put, call, takeEvery } from "redux-saga/effects";

import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* tostMessageTextSaga(){
    yield takeEvery(actionType.GET_TOST_MESSAGE_TEXT, handelTostMessageText);
}

function* handelTostMessageText(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.GOT_TOST_MESSAGE_TEXT, payload: response})
    } catch (error) {
        console.log(error);
    }
}