import * as actionType from "../actions/actionTypes";

const initialState = {
    quizSubjectTypes: []
}


export default function getQuizSubjectTypes(state = initialState, action) {
    switch (action.type) {

        case actionType.GOT_QUIZ_SUBJECT_TYPES: {
            return {
                ...state,
                quizSubjectTypes: action.payload,
            };
        }

        default: {
            return { ...state };
        }
        
    }
}