import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import noAccess from "../../assets/noAccess.svg";

function AccessDeniedPage(props) {
  return (
    <div>
      <Meta title="No access" />
      <div className="flex justify-center items-center mt-8">
        <img src={noAccess} alt="No access" />
      </div>
    </div>
  );
}

export default AccessDeniedPage;
