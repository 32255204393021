import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const DiscussSection = lazy(() => import('../../components/miscellaneous/discuss-section/view/DiscussSection'));

function DiscussPage(props) {

  return (
    <>
      <Meta title="Discuss" />
      <Suspense fallback={<div>Loading...</div>}>
        <DiscussSection />
      </Suspense>
    </>
  );
}

export default requireAuth(DiscussPage);
