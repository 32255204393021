import { Navigate } from "react-router-dom";
import useAuthorize from "../../hooks/authentication-and-user-information/useAuthorize";
import { requireAuth } from "../../util/auth";

function PrivateRoute({ children, permissions }) {
  const { isAuthorize } = useAuthorize();
  const hasPermission = isAuthorize(permissions);
  
  return hasPermission ? (
    <>{children}</>
  ) : (
    <Navigate to="/unauthorized" replace />
  );
}
export default requireAuth(PrivateRoute);
