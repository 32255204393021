import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const AssignIssueListSection = lazy(() => import('../../components/support-management/assign-issue-list-section/view/AssignIssueListSection'));

function AssignIssueListPage(props) {
  return (
    <>
      <Meta title="Assign issue-list" />
      <Suspense fallback={<div>Loading...</div>}>
        <AssignIssueListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AssignIssueListPage);
