import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import RefundPolicySection from "../../components/content-and-information-pages/refund-policy/view/RefundPolicySection";

function RefundPolicyPage(props) {
  return (
    <>
      <Meta title="Refund Policy" />
      <RefundPolicySection />
    </>
  );
}

export default RefundPolicyPage;
