import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const DeletedQuizListSection = lazy(() => import('../../components/archive-and-deleted-data/deleted-quiz-list-section/view/DeletedQuizListSection'));

function DeletedQuizListPage(props) {
  return (
    <>
      <Meta title="Deleted quiz list" />
      <Suspense fallback={<div>Loading...</div>}>
        <DeletedQuizListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(DeletedQuizListPage);