import { put, call, takeEvery } from "redux-saga/effects";

import { dataPromise } from "../../../../../util/db";
import * as actionType from "../action/actionTypes";

export default function* isFloatingSearchBoxShownSaga(){
    yield takeEvery(actionType.FLOATING_SEARCH_BOX_HIDDEN, handelIsFloatingSearchBoxShownSaga);
}

function* handelIsFloatingSearchBoxShownSaga(action){
    try {
        const response = yield call(dataPromise, action.payload);
        yield put({type: actionType.FLOATING_SEARCH_BOX_SHOWN, payload: response})
    } catch (error) {
        console.log(error);
    }
}