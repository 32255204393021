import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const AgentListSection = lazy(() => import('../../components/support-management/agent-list-section/view/AgentListSection'));

function AgentListPage(props) {
  return (
    <>
      <Meta title="Agent list" />
      <Suspense fallback={<div>Loading...</div>}>
        <AgentListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AgentListPage);